import React, { useState } from "react";
import { Add, Close, Refresh } from "@mui/icons-material";
import {
  Button,
  Card,
  Checkbox,
  ClickAwayListener,
  Fab,
  Grid,
  IconButton,
  TextField,
  ListItem,
} from "@mui/material";

import { sleep } from "../../Utils/index";
import { AutoSizer, List as ListVirtualized } from "react-virtualized";

const ItensAdicionar = ({
  _checked,
  lista,
  handleSelect,
  setNome,
  clear,
  setSelecionados,
  setChecked,
  remover,
}) => {
  if (!lista.length) {
    return null;
  }

  const renderRow = ({ index, key, style }) => {
    return (
      <ListItem key={key} style={style} sx={{ padding: 0 }}>
        <Grid container gap={1}>
          <Grid item xs={1}>
            <Checkbox
              value={lista[index]?.id}
              checked={_checked.includes(lista[index]?.id)}
              onChange={handleSelect}
              size="small"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={lista[index]?.nome}
              size="small"
              name="nome"
              onChange={(e) => setNome(e, index)}
              placeholder="Título da pesquisa"
              inputProps={{ maxLength: 80 }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              type="number"
              size="small"
              value={lista[index]?.quantidade}
              name="quantidade"
              onChange={(e) => setNome(e, index)}
              placeholder="QNT"
              inputProps={{ min: 0 }}
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton
              title="Retomar selecionados"
              onClick={async () => {
                clear(true);
                await sleep(500);
                setChecked([lista[index]?.id]);
                setSelecionados((old) =>
                  [...old, ...lista[index]?.itens].filter(
                    (i) => !remover.includes(i)
                  )
                );
              }}
            >
              <Refresh />
            </IconButton>
          </Grid>
        </Grid>
      </ListItem>
    );
  };

  return (
    <div
      style={{
        height: lista.length * 60,
        maxHeight: 300,
        width: 400,
        overflowX: "hidden",
      }}
    >
      <AutoSizer>
        {({ width, height }) => (
          <ListVirtualized
            scrollToIndex={lista.length - 1}
            width={width}
            height={height}
            rowHeight={50}
            rowRenderer={renderRow}
            rowCount={lista.length}
          />
        )}
      </AutoSizer>
    </div>
  );
};

const AddButton = ({
  lista = [],
  setLista,
  selecionados,
  clear,
  setSelecionados,
  addNotification,
  remover = [],
}) => {
  const [open, setOpen] = useState(false);
  const [_checked, setChecked] = useState([]);

  const setNome = (e, index) => {
    const { value, name } = e.target;

    setLista((prevLista) => {
      const updatedLista = [...prevLista];

      updatedLista[index] = { ...updatedLista[index], [name]: value };

      return updatedLista;
    });
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleNovaLista = () => {
    const id = crypto.randomUUID();
    setLista((old) => [
      ...old,
      { id: id, nome: "", quantidade: "", itens: [] },
    ]);
    setChecked([id]);
  };

  const handleSave = async () => {
    let _lista = Object.assign([], lista);

    _lista.forEach((i) => {
      if (_checked.includes(i.id)) {
        let nItens = i.itens.filter(
          (i) => remover.findIndex((j) => i.id === j.id) === -1
        );

        const quantidadeAtualizada = [
          ...new Set([...selecionados, ...nItens]),
        ].map((p) => ({ ...p, quantidade_item: i.quantidade }));

        i.itens = quantidadeAtualizada;
      }
    });

    setLista(_lista);

    addNotification(
      "Itens enviados para lista de impressão, para recuperar a pesquisa basta clicar no botão de reload ao lado da mesma",
      true,
      "success"
    );
    clear(true);
  };

  const handleRemove = () => {
    let _lista = Object.assign([], lista);
    setLista(_lista.filter((i) => !_checked.includes(i.id)));
  };

  const handleSelect = (e) => {
    const { checked, value } = e.target;

    if (checked) {
      setChecked([value]);
    } else {
      setChecked([]);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        {open ? (
          <Card
            className="card-adicionar"
            style={{ zIndex: 9, marginBottom: 10 }}
          >
            <Grid container style={{ overflow: "hidden" }}>
              {lista.length > 0 ? (
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4 style={{ margin: 8 }}>ITENS DA COTAÇÃO</h4>
                  <IconButton size="small" onClick={toggleOpen} color="error">
                    <Close />
                  </IconButton>
                </Grid>
              ) : null}

              <div>
                <ItensAdicionar
                  _checked={_checked}
                  lista={lista}
                  setChecked={setChecked}
                  remover={remover}
                  setNome={setNome}
                  setSelecionados={setSelecionados}
                  handleSelect={handleSelect}
                  clear={clear}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    width: "100%",
                    marginTop: 15,
                  }}
                >
                  {lista.length ? (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleSave}
                      style={{ width: 100 }}
                    >
                      Salvar
                    </Button>
                  ) : null}
                  {lista.length ? (
                    <Button
                      color="error"
                      onClick={handleRemove}
                      style={{ width: 100 }}
                      size="small"
                    >
                      Remover
                    </Button>
                  ) : null}
                </div>
                <Button
                  size="small"
                  style={{ width: 200, margin: "0 auto" }}
                  onClick={handleNovaLista}
                >
                  <Add /> Adicionar novo item
                </Button>
              </div>
            </Grid>
          </Card>
        ) : null}
        <Fab color="success" onClick={toggleOpen}>
          <Add />
        </Fab>
      </div>
    </ClickAwayListener>
  );
};

export default AddButton;
