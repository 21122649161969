import numeral from "numeral";

if (!numeral.locales["pt-br"]) {
  numeral.register("locale", "pt-br", {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    abbreviations: {
      thousand: "k",
      million: "m",
      billion: "b",
      trillion: "t",
    },
    ordinal: function (number) {
      return number === 1 ? "º" : "º";
    },
    currency: {
      symbol: "R$ ",
    },
  });
  numeral.locale("pt-br");
}

export const UFBR = [
  {
    nome: "Acre",
    sigla: "AC",
    id: 12,
  },
  {
    nome: "Alagoas",
    sigla: "AL",
    id: 27,
  },
  {
    nome: "Amapá",
    sigla: "AP",
    id: 16,
  },
  {
    nome: "Amazonas",
    sigla: "AM",
    id: 13,
  },
  {
    nome: "Bahia",
    sigla: "BA",
    id: 29,
  },
  {
    nome: "Ceará",
    sigla: "CE",
    id: 23,
  },
  {
    nome: "Distrito Federal",
    sigla: "DF",
    id: 53,
  },
  {
    nome: "Espírito Santo",
    sigla: "ES",
    id: 32,
  },
  {
    nome: "Goiás",
    sigla: "GO",
    id: 52,
  },
  {
    nome: "Maranhão",
    sigla: "MA",
    id: 21,
  },
  {
    nome: "Mato Grosso",
    sigla: "MT",
    id: 51,
  },
  {
    nome: "Mato Grosso do Sul",
    sigla: "MS",
    id: 50,
  },
  {
    nome: "Minas Gerais",
    sigla: "MG",
    id: 31,
  },
  {
    nome: "Paraná",
    sigla: "PR",
    id: 41,
  },
  {
    nome: "Paraíba",
    sigla: "PB",
    id: 25,
  },
  {
    nome: "Pará",
    sigla: "PA",
    id: 15,
  },
  {
    nome: "Pernambuco",
    sigla: "PE",
    id: 26,
  },
  {
    nome: "Piauí",
    sigla: "PI",
    id: 22,
  },
  {
    nome: "Rio Grande do Norte",
    sigla: "RN",
    id: 24,
  },
  {
    nome: "Rio Grande do Sul",
    sigla: "RS",
    id: 43,
  },
  {
    nome: "Rio de Janeiro",
    sigla: "RJ",
    id: 33,
  },
  {
    nome: "Rondônia",
    sigla: "RO",
    id: 11,
  },
  {
    nome: "Roraima",
    sigla: "RR",
    id: 14,
  },
  {
    nome: "Santa Catarina",
    sigla: "SC",
    id: 42,
  },
  {
    nome: "Sergipe",
    sigla: "SE",
    id: 28,
  },
  {
    nome: "São Paulo",
    sigla: "SP",
    id: 35,
  },
  {
    nome: "Tocantins",
    sigla: "TO",
    id: 17,
  },
];

export const maskCurrency = (valor, locale = "pt-BR", currency = "BRL") => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  }).format(valor);
};

const maskMoeda = (valor, locale = "pt-BR", currency = "BRL") => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).format(valor);
};

export const formatted = {
  numero: (value) => {
    return value.replace(/\D/g, "");
  },
  cep: (value) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d{3})/, "$1.$2")
      .replace(/(\d{3})(\d{3})/, "$1-$2")
      .replace(/(-\d{3})\d+?$/, "$1");
  },
  lote: (value) => {
    if (value.length >= 25) {
      return value.substring(0, 25);
    }
    return value.replace(/\D/g, "").replace(/(\d{14})(\d{1})/, "$1/$2");
  },
  telefone: (value) => {
    if (value.replace(/\D/g, "").length <= 2) {
      return value.length === 0 ? "" : value.replace(/\D/g, "");
    } else if (value.replace(/\D/g, "").length <= 10) {
      return value.length === 0
        ? ""
        : "(" +
            value
              .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
              .replace(/(\d{2})/, "$1) ") // captura 2 grupos de numero o primeiro de 2 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
              .replace(/(\d{4})(\d{1,2})/, "$1-$2")
              .replace(/(-\d{4})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    } else {
      return value.length === 0
        ? ""
        : "(" +
            value
              .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
              .replace(/(\d{2})(\d)/, "$1) $2 ") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
              .replace(/(\d{4})(\d{1,2})/, "$1-$2")
              .replace(/(-\d{4})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    }
  },
  cnpjCpfMask: (value) => {
    if (value.replace(/\D/g, "").length <= 11) {
      return value
        .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    } else {
      return value
        .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{2})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1/$2")
        .replace(/(\d{4})(\d{1,2})/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    }
  },
  cpfMask: (value) => {
    return value
      .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  },
  isEmail: (email) => {
    return /^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/.test(
      email
    );
  },
  horario: (value) => {
    return Number(value.slice(0, 2)) > 23 || Number(value.slice(3, 5)) > 59
      ? ""
      : value.replace(/\D/g, "").replace(/(\d{2})(\d)/, "$1:$2");
  },
  pis: (value) => {
    value = value.replace(/\D/g, ""); //Remove tudo o que não é dígito
    value = value.replace(/^(\d{3})(\d)/, "$1.$2"); //Coloca ponto entre o terceiro e o quarto dígitos
    value = value.replace(/^(\d{3})\.(\d{5})(\d)/, "$1.$2.$3"); //Coloca ponto entre o quinto e o sexto dígitos
    value = value.replace(/(\d{3})\.(\d{5})\.(\d{2})(\d)/, "$1.$2.$3.$4"); //Coloca ponto entre o décimo e o décimo primeiro dígitos
    return value;
  },
  moeda: (value) => {
    const onlyDigits = value
      .split("")
      .filter((s) => /\d/.test(s))
      .join("")
      .padStart(3, "0");
    const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2);

    return maskMoeda(digitsFloat);
  },
  valorMoeda: (value) =>
    Number(value.replace("R$", "").replaceAll(".", "").replace(",", ".")),
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const resizeImage = (base64Str, maxWidth = 180, maxHeight = 180) => {
  return new Promise((resolve) => {
    let img = new Image();
    img.src = base64Str;
    img.onload = () => {
      let canvas = document.createElement("canvas");
      const MAX_WIDTH = maxWidth;
      const MAX_HEIGHT = maxHeight;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL());
    };
  });
};

export const validarCNPJ = (cnpj) => {
  // Remove caracteres não numéricos
  cnpj = cnpj.replace(/[^\d]/g, "");

  // Verifica se o CNPJ tem 14 dígitos
  if (cnpj.length !== 14) {
    return false;
  }

  // Verifica se todos os dígitos são iguais (caso inválido)
  if (/^(\d)\1{13}$/.test(cnpj)) {
    return false;
  }

  // Função para calcular o dígito verificador
  const calcularDigito = (str, peso) => {
    let soma = 0;
    for (let i = 0; i < str.length; i++) {
      soma += parseInt(str[i]) * peso--;
      if (peso < 2) peso = 9;
    }
    const resto = soma % 11;
    return resto < 2 ? 0 : 11 - resto;
  };

  // Calcula o primeiro dígito verificador
  const digito1 = calcularDigito(cnpj.substring(0, 12), 5);

  // Calcula o segundo dígito verificador
  const digito2 = calcularDigito(cnpj.substring(0, 13), 6);

  // Compara os dígitos calculados com os dígitos reais
  return cnpj[12] == digito1 && cnpj[13] == digito2;
};
