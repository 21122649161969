import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SaveIcon from "@mui/icons-material/Save";
import {
  buscarLogs,
  buscarLogsPorId,
  deletarLog,
  salvarLogs,
} from "../../Services";
import { CircularProgress } from "@mui/material";
import moment from "moment";

const EditableInput = ({
  label,
  items,
  selectedItem,
  setSelectedItem,
  disabled,
  loading,
  hasQuantidade = null,
  onSave = async () => {},
  labelNovo,
  onPaste = async () => {},
  onDelete = async () => {},
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newItemName, setNewItemName] = useState("");
  const [quantidade, setQuantidade] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAddItem = async () => {
    if (newItemName.trim() === "") return;

    const newItem = {
      id: null,
      name: newItemName,
      quantidade: quantidade,
    };
    await onSave(newItem);
    setNewItemName("");
    setIsEditing(false);
  };

  const handleEditItem = async () => {
    if (selectedItem) {
      const editedItem = { ...selectedItem, name: newItemName, quantidade };
      await onSave(editedItem);
    }
    setIsEditing(false);
  };

  const handleDeleteItem = async () => {
    handleMenuClose();
    await onDelete(selectedItem);
  };

  const handleSetQuantidade = (e) => {
    setQuantidade(e.target.value);
  };

  const isExcelPaste = (clipboardData) => {
    return clipboardData.includes("\n");
  };

  const handlePaste = async (e) => {
    if (!hasQuantidade) {
      return;
    }

    const clipboardData = e.clipboardData.getData("text/plain");
    if (!isExcelPaste(clipboardData)) return;

    const rows = clipboardData.split("\n").map((row) => row.split("\t"));

    const newData = rows
      .map((row) => {
        const id = crypto.randomUUID();

        if (isNaN(row[1])) {
          return null;
        }

        return {
          id,
          name: row[0] || "",
          quantidade: Number(row[1]) || 0,
          itens: [],
        };
      })
      .filter((p) => !!p?.name);

    setNewItemName("");
    setQuantidade("");
    await onPaste(newData);

    setIsEditing(false);
  };

  useEffect(() => {
    if (hasQuantidade) {
      setQuantidade(selectedItem?.quantidade || 0);
    }
  }, [selectedItem]);

  return (
    <Box display="flex" alignItems="center" gap={1}>
      {isEditing ? (
        <TextField
          size="small"
          value={newItemName}
          onChange={(e) => setNewItemName(e.target.value)}
          label={selectedItem ? `Editar ${label}` : `Novo ${label}`}
          fullWidth
          onPaste={handlePaste}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              if (selectedItem) {
                handleEditItem();
              } else {
                handleAddItem();
              }
            }
          }}
        />
      ) : (
        <Autocomplete
          loadingText="Carregando informções..."
          size="small"
          loading={loading}
          disabled={disabled}
          fullWidth
          value={selectedItem}
          noOptionsText="Nenhuma opção encontrada"
          onChange={(event, newValue) => {
            setSelectedItem(newValue);
          }}
          options={items}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label={label} fullWidth />
          )}
        />
      )}

      {hasQuantidade ? (
        <TextField
          key={selectedItem?.quantidade}
          size="small"
          label="Quantidade"
          value={quantidade}
          onChange={handleSetQuantidade}
          disabled={!isEditing}
        />
      ) : null}

      {loading ? (
        <CircularProgress size={18} style={{ padding: 12 }} />
      ) : isEditing ? (
        <IconButton
          onClick={() => {
            if (selectedItem) {
              handleEditItem();
            } else {
              handleAddItem();
            }
          }}
          color="primary"
        >
          <SaveIcon />
        </IconButton>
      ) : (
        <IconButton onClick={handleMenuOpen}>
          <MoreVertIcon />
        </IconButton>
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            setIsEditing(true);
            setSelectedItem(null);
            setNewItemName("");
            setQuantidade("");
            handleMenuClose();
          }}
        >
          {labelNovo}
        </MenuItem>
        {!!selectedItem && (
          <MenuItem
            onClick={() => {
              setIsEditing(true);
              setNewItemName(selectedItem.name);
              handleMenuClose();
              setQuantidade(selectedItem?.quantidade || "");
            }}
          >
            Editar
          </MenuItem>
        )}
        {!!selectedItem && (
          <MenuItem onClick={handleDeleteItem}>Excluir</MenuItem>
        )}
      </Menu>
    </Box>
  );
};

const EditableAutocomplete = ({
  lista,
  setLista,
  setIdSelecionado = () => {},
  setTituloPesquisa = () => {},
}) => {
  const [items1, setItems1] = useState([]);
  const [items2, setItems2] = useState([]);

  const [selectedItem1, setSelectedItem1] = useState(null);
  const [selectedItem2, setSelectedItem2] = useState(null);

  const [loading, setLoading] = useState(false);

  const buscarCotacoes = async () => {
    const res = await buscarLogs();

    if (res.success) {
      if (res.data.length) {
        setItems1(res.data.map((p) => ({ id: p.id, name: p.titulo })));
      } else {
        setItems1({ id: crypto.randomUUID(), name: "PESQUISA PADRÃO" });
      }
    }
  };

  const buscarCotacoesPorId = async () => {
    if (!selectedItem1?.id) return;
    setSelectedItem2(null);
    setLoading(true);
    const res = await buscarLogsPorId(selectedItem1?.id);
    setLoading(false);

    if (res.success) {
      const _itens = [];
      const pesquisasSalvas = [];

      for (let index = 0; index < res.data.data.pesquisas.length; index++) {
        const element = res.data.data.pesquisas[index];
        const id = crypto.randomUUID();

        const a = {
          id,
          name: element.nome,
          quantidade: element.quantidade,
        };

        const b = { ...element, id };

        _itens.push(a);
        pesquisasSalvas.push(b);
      }

      setLista(pesquisasSalvas);

      setItems2(_itens);
    }
  };

  const handlePaste = async (elements = []) => {
    setLoading(true);
    const _id = selectedItem1?.id || null;

    const listaAtualizada = [...lista, ...elements].map((p) => ({
      ...p,
      nome: p.name || p.nome,
    }));
    const listaSalvar = listaAtualizada.map((p) => ({
      ...p,
      itens: p.itens.map((x) => x._id),
    }));

    const body = {
      titulo: selectedItem1?.name,
      itens: listaSalvar,
      date: moment(),
    };

    await salvarLogs(body, _id);
    setLoading(false);

    setItems2(listaAtualizada.map((p) => ({ ...p, name: p.nome })));
    setLista(listaAtualizada);
  };

  const handleSaveCotacao = async (selectedItem) => {
    const _id = selectedItem?.id || null;

    const listaSalvar = _id
      ? lista.map((p) => ({ ...p, itens: p.itens.map((x) => x._id) }))
      : [];

    const body = {
      titulo: selectedItem?.name,
      itens: listaSalvar,
      date: moment(),
    };

    setLoading(true);
    const res = await salvarLogs(body, _id);
    setLoading(false);
    if (_id) {
      setSelectedItem1(selectedItem);
      setItems1((prevItems) =>
        prevItems.map((item) =>
          item.id === selectedItem.id
            ? { ...item, name: selectedItem.name }
            : item
        )
      );
    } else {
      setSelectedItem1({ ...selectedItem, id: res.id });
      setItems1([...items1, { ...selectedItem, id: res.id }]);
    }

    setLista(body.itens);

    return res?.id || _id;
  };

  const handleSaveItem = async (selectedItem) => {
    setLoading(true);
    const id = crypto.randomUUID();
    const _lista = [...lista];

    const index = _lista.findIndex((p) => p.id == selectedItem?.id);

    if (index != -1) {
      _lista[index].nome = selectedItem?.name;
      _lista[index].quantidade = selectedItem?.quantidade || 0;

      setSelectedItem2(selectedItem);
      setItems2((prevItems) => {
        if (prevItems[index].name) {
          prevItems[index].name = selectedItem.name;
          prevItems[index].quantidade = selectedItem.quantidade || "";
        }
        return prevItems;
      });
    } else {
      const newItem = {
        nome: selectedItem?.name,
        quantidade: selectedItem?.quantidade || 0,
        id,
        itens: [],
      };

      _lista.push(newItem);

      setSelectedItem2({ ...selectedItem, id });
      setItems2((prevItems) => [...prevItems, { ...selectedItem, id }]);
    }

    const listaSalvar = _lista.map((p) => ({
      ...p,
      itens: p.itens.map((x) => x._id),
    }));

    const body = {
      titulo: selectedItem1?.name,
      itens: listaSalvar,
      date: moment(),
    };

    setLista(_lista);

    const _id = selectedItem1?.id || null;
    await salvarLogs(body, _id);
    setLoading(false);
  };

  const handleExcluirItems = async (selectedItem) => {
    setLoading(true);

    const listaAtualizada = lista.filter((p) => p.id != selectedItem.id);

    const listaSalvar = listaAtualizada.map((p) => ({
      ...p,
      itens: p.itens.map((x) => x._id),
    }));

    const body = {
      titulo: selectedItem1?.name,
      itens: listaSalvar,
      date: moment(),
    };

    await salvarLogs(body, selectedItem1?.id);
    setItems2((prevItems) =>
      prevItems.filter((item) => item.id !== selectedItem.id)
    );
    setSelectedItem2(null);
    setLista(listaAtualizada);
    setLoading(false);
  };

  const handleExcluirCotacao = async (selectedItem) => {
    setLoading(true);

    await deletarLog(selectedItem.id);

    setSelectedItem1(null);
    setLista([]);

    setLoading(false);
  };

  useEffect(() => {
    buscarCotacoes();
  }, []);

  useEffect(() => {
    sessionStorage.setItem(
      "titulo-cotacao",
      selectedItem1?.nome || selectedItem1?.name
    );
    setTituloPesquisa(selectedItem1);
    buscarCotacoesPorId();
  }, [selectedItem1]);

  useEffect(() => {
    setIdSelecionado(selectedItem2?.id);
  }, [selectedItem2?.id]);

  return (
    <Box sx={{ flexGrow: 1, paddingInline: 3, paddingBlock: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={5}>
          <EditableInput
            label="Cotação"
            items={items1}
            setItems={setItems1}
            selectedItem={selectedItem1}
            setSelectedItem={setSelectedItem1}
            loading={loading}
            disabled={loading}
            onSave={handleSaveCotacao}
            labelNovo={"Nova cotação"}
            onDelete={handleExcluirCotacao}
          />
        </Grid>

        <Grid item xs={12} sm={7}>
          <EditableInput
            key={`${selectedItem1?.id}`}
            loading={loading}
            disabled={loading || !selectedItem1?.id}
            label="Item"
            items={items2}
            setItems={setItems2}
            selectedItem={selectedItem2}
            setSelectedItem={setSelectedItem2}
            hasQuantidade={true}
            onSave={handleSaveItem}
            labelNovo={"Novo item"}
            onPaste={handlePaste}
            onDelete={handleExcluirItems}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditableAutocomplete;
