import * as React from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import {
  Autocomplete,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Toolbar,
  Typography,
  Grid,
  Chip,
  Checkbox,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import {
  Construction,
  CalendarMonth,
  Map,
  LocationCity,
  Gavel,
  LocalShipping,
  Straighten,
  AttachMoney,
  Badge,
} from "@mui/icons-material";

import "./style.css";
import DefaultAutocomplete from "../Autocomplete";
import DefaultDatePicker from "../DefaultDatePicker";
import { formatted, UFBR } from "../../Utils";
import { buscarCidadePorUf } from "../../Services";
import moment from "moment";

const drawerWidth = 310;

export const fonte = [
  { id: 1, nome: "Compras net" },
  { id: 2, nome: "TCE SP" },
  { id: 3, nome: "Bec SP" },
  { id: 4, nome: "Portal de compras" },
  { id: 5, nome: "PNCP" },
];

const FILTROS = [
  {
    name: "matServ",
    label: "Material/Servicos",
    icon: Construction,
    type: "text",
  },
  {
    name: "pesquisaAvancada",
    label: "Pesquisa avançada",
    // icon: Construction,
    type: "checkbox",
  },
  {
    name: "contratoid",
    label: "Contrato",
    icon: Badge,
    type: "text",
  },
  {
    name: "dataInicial",
    label: "Data inicial",
    icon: CalendarMonth,
    type: "date",
  },
  {
    name: "dataFinal",
    label: "Data final",
    icon: CalendarMonth,
    type: "date",
  },
  {
    name: "uf",
    label: "Estado",
    icon: Map,
    type: "autocomplete",
    options: UFBR,
  },
  {
    name: "cidade",
    label: "Município",
    icon: LocationCity,
    type: "tags",
  },
  // { name: "orgao", label: "Orgão", icon: Gavel, type: "text" },
  {
    name: "cnpjFornecedor",
    label: "CNPJ fornecedor",
    icon: LocalShipping,
    type: "text",
  },
  { name: "unidade", label: "Unidade", icon: Straighten, type: "text" },
  { name: "valorMin", label: "Preco mínimo", icon: AttachMoney, type: "text" },
  { name: "valorMax", label: "Preço máximo", icon: AttachMoney, type: "text" },
  {
    name: "fonte",
    label: "Fonte",
    icon: Gavel,
    type: "tags",
    options: fonte,
  },
];

export default function TemporaryDrawer({
  getData = () => {},
  clearHeader = () => {},
  open,
  setOpen,
}) {
  const [filtros, setFiltros] = React.useState({
    dataInicial: moment().subtract(6, "months"),
    dataFinal: moment(),
    matServ: sessionStorage.getItem("matServ"),
    pesquisaAvancada: true,
  });
  const [options, setOptions] = React.useState([]);

  const buscarCidades = async () => {
    let sigla = UFBR.filter((i) => i.nome === filtros?.uf)[0].sigla;
    const res = await buscarCidadePorUf(sigla);

    if (res.success) {
      let value = res.data;
      setOptions(value);
      setFiltros({ ...filtros, cidade: [] });
    }
  };

  const handleChange = (e) => {
    let value = e.target.value;
    if (e.target.name === "cnpjFornecedor") {
      value = formatted.cnpjCpfMask(value);
    }
    if (e.target.name === "valorMin" || e.target.name === "valorMax") {
      value = formatted.moeda(value);
    }
    setFiltros({ ...filtros, [e.target.name]: value });
  };

  const limparFiltros = () => {
    setFiltros({
      dataInicial: moment().subtract(12, "months"),
      dataFinal: moment(),
    });
  };

  const handlePesquisar = () => {
    sessionStorage.removeItem("matServ");
    clearHeader();
    getData(filtros, false);
  };

  const onPress = (e) => {
    if (e.keyCode === 13) handlePesquisar();
  };

  const toggleOpen = () => setOpen(!open);

  React.useEffect(() => {
    if (!filtros?.uf) {
      setFiltros({ ...filtros, cidade: [] });
      setOptions([]);
    } else {
      buscarCidades();
    }
  }, [filtros?.uf]);

  return (
    <React.Fragment>
      <div
        className={open ? "float-button open" : "float-button"}
        onClick={toggleOpen}
      >
        {open ? (
          <CloseIcon sx={{ color: "#fff" }} />
        ) : (
          <ArrowForwardIosIcon sx={{ color: "#fff" }} />
        )}
      </div>
      <Drawer
        anchor={"left"}
        open={open}
        variant="persistent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            top: 80,
          },
          border: "none",
        }}
        elevation={6}
      >
        <Toolbar
          sx={{
            justifyContent: "center",
            backgroundColor: "#1976d2",
            color: "white",
            margin: 0,
          }}
        >
          <Typography
            sx={{
              mr: 2,
              ml: 1,
              display: { xs: "none", md: "flex" },
              fontWeight: 700,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none",
              alignItems: "center",
            }}
          >
            FILTROS DE PESQUISA
          </Typography>
        </Toolbar>
        <Stack
          spacing={2}
          sx={{
            height: "calc(100% - 260px)",
            overflowY: "scroll",
            overflowX: "hidden",
            "::-webkit-scrollbar": {
              width: 10,
            },
            "::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: 10,
            },
            "::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
            padding: "10px",
          }}
        >
          {FILTROS.map((item, index) => {
            if (item.type === "tags") {
              return (
                <Grid item xs={item.size} key={index}>
                  <Autocomplete
                    multiple
                    options={item.options || options}
                    // freeSolo
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={index}
                          variant="outlined"
                          label={option.nome}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    value={filtros?.[item.name]}
                    onChange={(e, v, type) => {
                      setFiltros({ ...filtros, [item.name]: v });
                    }}
                    noOptionsText="Nenhum resultado"
                    getOptionLabel={(option) => option.nome}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="default-input-padding"
                        variant="outlined"
                        size="small"
                        fullWidth
                        label={item.label}
                      />
                    )}
                  />
                </Grid>
              );
            }
            if (item.type === "autocomplete") {
              return (
                <DefaultAutocomplete
                  key={index}
                  label={item.label}
                  name={item.name}
                  value={filtros?.[item.name]}
                  icon={<item.icon />}
                  onChange={handleChange}
                  options={item.options || options}
                  onKeyUp={onPress}
                />
              );
            }
            if (item.type === "date") {
              return (
                <DefaultDatePicker
                  key={index}
                  label={item.label}
                  onChange={(e) => {
                    const _filtros = { ...filtros };

                    _filtros[item.name] = e;

                    const max = 12;

                    if (item.name == "dataInicial") {
                      const diff = Math.abs(
                        moment(e).diff(filtros?.dataFinal, "months")
                      );

                      if (
                        (moment(e).isAfter(filtros?.dataFinal) || diff > max) &&
                        !!filtros?.dataFinal
                      ) {
                        _filtros.dataFinal = moment(e).add(max, "months");
                      }
                    }

                    if (item.name == "dataFinal") {
                      const diff = Math.abs(
                        moment(e).diff(filtros?.dataInicial, "months")
                      );
                      if (
                        (moment(e).isBefore(filtros?.dataInicial) ||
                          diff > max) &&
                        !!filtros?.dataInicial
                      ) {
                        _filtros.dataInicial = moment(e).subtract(
                          max,
                          "months"
                        );
                      }
                    }

                    setFiltros(_filtros);
                  }}
                  value={filtros?.[item.name]}
                  onKeyDown={onPress}
                />
              );
            }
            if (item.type === "select") {
              return (
                <TextField
                  key={index}
                  select
                  label={item.label}
                  name={item.name}
                  onChange={handleChange}
                  value={filtros?.[item.name] || ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        style={{ marginRight: 7 }}
                        position="start"
                      >
                        <item.icon />
                      </InputAdornment>
                    ),
                  }}
                >
                  {item.options.map((i, _index) => (
                    <MenuItem key={_index} value={i.sigla}>
                      {i.nome}
                    </MenuItem>
                  ))}
                </TextField>
              );
            }

            if (item.type === "checkbox") {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: 20,
                    marginTop: 4,
                  }}
                >
                  <Checkbox
                    size="small"
                    onChange={(e) =>
                      setFiltros({
                        ...filtros,
                        pesquisaAvancada: e.target.checked,
                      })
                    }
                    checked={filtros?.[item.name] || false}
                  />
                  <small>{item.label}</small>
                </div>
              );
            } else {
              return (
                <TextField
                  key={index}
                  size="small"
                  variant="outlined"
                  label={item.label}
                  name={item.name}
                  type={item.type}
                  style={{ marginTop: index === 0 && 15 }}
                  value={filtros?.[item.name] || ""}
                  onChange={handleChange}
                  onKeyDown={onPress}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        style={{ marginRight: 7 }}
                        position="start"
                      >
                        <item.icon />
                      </InputAdornment>
                    ),
                  }}
                />
              );
            }
          })}
        </Stack>
        <Toolbar sx={{ flexDirection: "column", alignItems: "normal", pt: 2 }}>
          <Button variant="contained" color="primary" onClick={handlePesquisar}>
            Pesquisar
          </Button>
          <Button color="primary" onClick={limparFiltros}>
            Limpar filtros
          </Button>
        </Toolbar>
      </Drawer>
    </React.Fragment>
  );
}
