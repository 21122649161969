import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  IconButton,
  Alert,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DefaultModal from "../DefaultModal";
import { putCotacaoInterna, putIncluirArquivoCotacao } from "../../Services";
import { formatted, validarCNPJ } from "../../Utils";
import { LoadingButton } from "@mui/lab";

const DEFAULT_FORM = {
  objeto_licitacao: "",
  cnpj: "",
  file: "",
  fileName: "",
  mimeType: "",
  itens: [
    {
      descricao_item: "",
      quantidade_item: "",
      descricao_detalhada_item: "",
      marca: "",
      modelo: "",
      fabricante: "",
      unidade_fornecimento: "",
      valorUnitario: "",
    },
  ],
};

const FormularioCotacao = ({ open, onClose, addNotification }) => {
  const [formData, setFormData] = useState(DEFAULT_FORM);
  const [exibirAviso, setExibirAviso] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e, index) => {
    let { name, value } = e.target;

    if (index !== undefined) {
      // Atualiza um item específico
      if (name === "valorUnitario") {
        value = formatted.moeda(value);
      }

      if (name === "quantidade_item") {
        value = Number(formatted.numero(value));
      }

      const novosItens = [...formData.itens];
      novosItens[index] = { ...novosItens[index], [name]: value };
      setFormData({ ...formData, itens: novosItens });
    } else {
      // Atualiza os dados gerais
      if (name === "cnpj") {
        value = formatted.cnpjCpfMask(value);
      }

      setFormData({ ...formData, [name]: value });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    const maxSize = 80 * 1024 * 1024; // 80 MB

    if (file.size > maxSize) {
      addNotification(
        "Arquivo muito grande. O tamanho máximo permitido é 80 MB.",
        true,
        "info"
      );
      return;
    }

    if (file) {
      setFormData({
        ...formData,
        file: file,
        fileName: file.name,
        mimeType: file.type,
      });
    }
  };

  const handleAddItem = () => {
    // Adiciona um novo item vazio à lista de itens
    setFormData({
      ...formData,
      itens: [
        ...formData.itens,
        {
          descricao_item: "",
          quantidade_item: "",
          descricao_detalhada_item: "",
          marca: "",
          modelo: "",
          fabricante: "",
          unidade_fornecimento: "",
          valorUnitario: "",
        },
      ],
    });
  };

  const handleRemoveItem = (index) => {
    // Remove um item específico da lista
    const novosItens = formData.itens.filter((_, i) => i !== index);
    setFormData({ ...formData, itens: novosItens });
  };

  const showMessage = () => {
    setExibirAviso(true);
    const box = document.getElementById("box-nova-cotacao");
    box.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!exibirAviso) {
      showMessage();
      return;
    }

    const _formData = { ...formData };

    // Validação básica
    if (
      !formData.objeto_licitacao ||
      !formData.cnpj ||
      !formData.file ||
      formData.itens.length === 0
    ) {
      addNotification("Preencha todos os campos obrigatórios.", true, "info");
      return;
    }

    if (!validarCNPJ(formData.cnpj)) {
      addNotification("O CNPJ informado não é válido.", true, "info");
      return;
    }

    _formData.cnpj = formatted.numero(_formData.cnpj);

    for (let index = 0; index < _formData.itens.length; index++) {
      let { valorUnitario, quantidade_item } = _formData.itens[index];

      valorUnitario = Number(
        valorUnitario.replace("R$ ", "").replaceAll(".", "").replace(",", ".")
      );

      if (!quantidade_item) {
        addNotification("Prencha a quantidade", true, "info");

        return;
      }

      if (!valorUnitario) {
        addNotification(
          `Prencha o valor unitário do item ${index + 1}`,
          true,
          "info"
        );

        return;
      }

      _formData.itens[index].valorUnitario = valorUnitario;
    }

    try {
      setLoading(true);
      const { file } = formData;
      delete _formData.file;

      const response = await putCotacaoInterna(_formData);

      if (!response.success) {
        return;
      }

      const subirContrato = await putIncluirArquivoCotacao(response.data, file);

      if (!subirContrato.success) {
        return;
      }

      addNotification("Cotação incluída com sucesso!", true, "success");

      onClose();
    } catch (error) {
      addNotification(
        "Erro ao enviar o formulário. Verifique os dados e tente novamente.",
        true,
        "info"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setFormData(DEFAULT_FORM);
    setExibirAviso(false);
  }, [open]);

  return (
    <DefaultModal
      style={{ maxHeight: "85vh", overflowY: "hidden" }}
      title={"Nova cotação"}
      open={open}
      onClose={onClose}
    >
      <Box>
        {exibirAviso ? (
          <Alert severity="warning" style={{ marginBottom: 15 }}>
            Atenção! Antes de enviar revise os dados pois os mesmos não poderão
            ser alterados posteriormente, após a revisão clique em enviar
            novamente!
          </Alert>
        ) : null}
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}
            id="box-nova-cotacao"
            style={{
              overflowY: "scroll",
              height: "50vh",
              paddingRight: 15,
              marginTop: 20,
              paddingBottom: 10,
            }}
          >
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Objeto da Licitação"
                name="objeto_licitacao"
                value={formData.objeto_licitacao}
                onChange={handleChange}
                size="small"
                multiline
                rows={4}
                required
                inputProps={{ maxLength: 200 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="CNPJ do Fornecedor"
                name="cnpj"
                value={formData.cnpj}
                onChange={handleChange}
                size="small"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <input
                type="file"
                accept=".pdf"
                onChange={handleFileChange}
                required
              />
            </Grid>

            {/* Itens dinâmicos */}
            {formData.itens.map((item, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle1" gutterBottom>
                      Item {index + 1}
                    </Typography>
                    {formData.itens.length > 1 && (
                      <IconButton
                        color="error"
                        onClick={() => handleRemoveItem(index)}
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Descrição do Item"
                    name="descricao_item"
                    value={item.descricao_item}
                    onChange={(e) => handleChange(e, index)}
                    size="small"
                    required
                    inputProps={{ maxLength: 150 }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Descrição Detalhada do Item"
                    name="descricao_detalhada_item"
                    value={item.descricao_detalhada_item}
                    onChange={(e) => handleChange(e, index)}
                    multiline
                    rows={4}
                    size="small"
                    required
                    inputProps={{ maxLength: 350 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Marca"
                    name="marca"
                    value={item.marca}
                    onChange={(e) => handleChange(e, index)}
                    size="small"
                    required
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Modelo"
                    name="modelo"
                    value={item.modelo}
                    onChange={(e) => handleChange(e, index)}
                    size="small"
                    required
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Fabricante"
                    name="fabricante"
                    value={item.fabricante}
                    onChange={(e) => handleChange(e, index)}
                    size="small"
                    required
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Quantidade"
                    name="quantidade_item"
                    value={item.quantidade_item}
                    onChange={(e) => handleChange(e, index)}
                    size="small"
                    required
                    inputProps={{ maxLength: 7 }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Unidade de Fornecimento"
                    name="unidade_fornecimento"
                    value={item.unidade_fornecimento}
                    onChange={(e) => handleChange(e, index)}
                    size="small"
                    required
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Valor Unitário"
                    name="valorUnitario"
                    value={item.valorUnitario}
                    onChange={(e) => handleChange(e, index)}
                    size="small"
                    required
                    inputProps={{ maxLength: 15 }}
                  />
                </Grid>
              </React.Fragment>
            ))}

            {/* Botão para adicionar itens */}
          </Grid>
          <Grid container spacing={2} style={{ paddingTop: 40 }}>
            <Grid item xs={12}>
              <LoadingButton
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={handleAddItem}
                fullWidth
                size="small"
                loading={loading}
              >
                Incluir Item
              </LoadingButton>
            </Grid>

            {/* Botão de envio */}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </DefaultModal>
  );
};

export default FormularioCotacao;
