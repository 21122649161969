import axios from "axios";
import moment from "moment/moment";
import { formatted } from "../Utils";
import pako from "pako";

const url = {
  producao: "https://precospublicosonline.com.br/api",
  homologacao: "http://localhost:3001/api",
  ngrok:
    "https://7d91-2804-1b3-6149-a2f4-e54b-1c70-762d-520e.ngrok-free.app/api",
};

const api = axios.create({
  baseURL: url.producao,
});

api.interceptors.request.use(
  async (config) => {
    let token = sessionStorage.getItem("token");

    config.headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const validateUser = async () => {
  const token = sessionStorage.getItem("token");

  if (!token) {
    return { success: false };
  } else {
    const route = `/auth/validate-user?token=${token}`;
    return await api
      .get(route)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => err.response.data);
  }
};

export const buscarCidadePorUf = async (uf) => {
  const route = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`;

  return await axios
    .get(route)
    .then((res) => {
      if (res.status === 200) {
        return {
          success: true,
          data: res.data.map((i) => ({ nome: i.nome, id: i.id })),
        };
      } else {
        return {
          success: false,
          data: [],
        };
      }
    })
    .catch((error) => {
      return {
        success: false,
        data: [],
      };
    });
};

export const buscarCep = async (cep) => {
  const route = `https://viacep.com.br/ws/${cep}/json/`;

  return await axios
    .get(route)
    .then((res) => {
      if (res.status === 200) {
        return {
          success: true,
          data: res.data,
        };
      } else {
        return {
          success: false,
          message: "ocorreu um erro ",
        };
      }
    })
    .catch((error) => {
      return {
        success: false,
      };
    });
};

export const consultarBanco = async (data) => {
  const res = `/buscar-contratos/consultar`;

  return await api
    .put(res, data)
    .then((res) => {
      if (res.status === 200) {
        return {
          data: res.data.data,
          registros: res.data.registros,
          success: true,
        };
      } else {
        return {
          success: false,
          message: "ocorreu um erro ao buscar os dados",
        };
      }
    })
    .catch((error) => {
      return {
        success: false,
        message: "Ocorreu um error",
      };
    });
};

export const userLogin = async (data) => {
  const route = `/auth/login`;

  return await api
    .post(route, data)
    .then((res) => {
      if (res.status === 200) {
        sessionStorage.setItem("token", res.data.data);
        sessionStorage.setItem("isAdm", res.data.isAdm);
        sessionStorage.setItem("user", res.data.user);
        sessionStorage.setItem("brasao", res.data.brasao);
        return {
          data: res.data,
          success: true,
        };
      } else {
        return {
          success: false,
          message: "ocorreu um erro ao buscar os dados",
        };
      }
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const resetSenha = async (data) => {
  const route = `/auth/reset-password`;

  return await api
    .post(route, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        return {
          success: false,
          message: "ocorreu um erro ao buscar os dados",
        };
      }
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const novoUsuario = async (data) => {
  const route = `/auth/register`;

  return await api
    .post(route, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        return {
          success: false,
          message: "ocorreu um erro ao cadastrar o usuário",
        };
      }
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const relatorioPdf = async (filters) => {
  const route = `/relatorio/pdf`;

  const arrString = JSON.stringify(filters);
  const compressedFilters = pako.gzip(arrString);

  return await api
    .put(route, compressedFilters, {
      responseType: "arraybuffer", // Expecting a binary response
      headers: {
        "Content-Type": "application/octet-stream", // Sending binary data
        body: compressedFilters,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Relatório ${moment().format("DD/MM/YYYY HH:mm")}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up after download

        return {
          success: true,
        };
      } else {
        return {
          success: false,
          message:
            "Não foram encontrados relatórios para os filtros selecionados.",
        };
      }
    })
    .catch((error) => {
      console.error(error);
      return {
        success: false,
        message:
          "Não foram encontrados relatórios para os filtros selecionados.",
      };
    });
};

export const relatorioExcel = async (filters) => {
  const route = `/relatorio/excel`;

  return await api
    .put(route, filters, {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "blob",
      },
    })
    .then((res) => {
      if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Relatório ${moment().format("DD/MM/YYYY HH:mm")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        return {
          success: true,
        };
      } else {
        return {
          success: false,
          message:
            "Não foram encontrados relatórios para os filtros selecionados.",
        };
      }
    })
    .catch((error) => {
      return {
        success: false,
        message:
          "Não foram encontrados relatórios para os filtros selecionados.",
      };
    });
};

export const buscarLogs = async () => {
  const route = `/log/buscar`;

  return await api
    .get(route)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        return {
          success: false,
          message: "ocorreu um erro ao cadastrar o usuário",
        };
      }
    })
    .catch((error) => {
      return { success: false, message: error.response.data };
    });
};

export const buscarLogsPorId = async (id) => {
  const route = `/log/buscar-por-id?id=${id}`;

  return await api
    .get(route)
    .then((res) => {
      if (res.status === 200) {
        return { success: true, data: res.data };
      } else {
        return {
          success: false,
          message: "ocorreu um erro ao cadastrar o usuário",
        };
      }
    })
    .catch((error) => {
      return { success: false, message: error.response.data };
    });
};

export const salvarLogs = async (body, id) => {
  const route = `/log/salvar`;

  if (id) {
    const _route = `/log/editar?id=${id}`;

    return await api
      .put(_route, body)
      .then((res) => {
        if (res.status === 200) {
          return { success: true, msg: res.msg };
        }
      })
      .catch((err) => {
        return {
          success: false,
          msg:
            err.response.data.msg ||
            "Ocorreu um erro, tente novamente mais tarde!",
        };
      });
  }

  return await api
    .post(route, body)
    .then((res) => {
      if (res.status === 200) {
        return { success: true, msg: res.msg, id: res.data.id };
      }
    })
    .catch((err) => {
      return {
        success: false,
        msg:
          err.response.data.msg ||
          "Ocorreu um erro, tente novamente mais tarde!",
        id: null,
      };
    });
};

export const deletarLog = async (id) => {
  const route = `/log/deletar?id=${id}`;

  return await api
    .delete(route)
    .then((res) => {
      if (res.status === 200) {
        return { success: true, msg: res.msg };
      }
    })
    .catch((err) => ({ success: false, msg: res.msg }));
};

export const getUasgs = async (nome) => {
  const route = `/buscar-contratos/consultar-uasg-nome?nome=${nome}`;

  return await api
    .get(route)
    .then((res) => {
      if (res.status === 200) {
        return { success: true, data: res.data };
      }
    })
    .catch((err) => ({ success: false }));
};

export const integracaoJSON = async (uasg, file) => {
  const { nome, cep, municipio, sigla_uf, link } = uasg;
  let route = `/integracao/json-integracao?nome=${nome}&cep=${cep}&municipio=${municipio}&uf=${sigla_uf}&link=${link}`;

  const formData = new FormData();

  formData.append("file", file);

  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  if (uasg._id) {
    route += `&id=${uasg._id}`;
  }

  return await api
    .post(route, formData, config)
    .then((res) => {
      if (res.status === 200) {
        return { success: true, data: res.data };
      }
    })
    .catch((err) => ({ success: false, message: err.response.data }));
};

export const getClientes = async (nome) => {
  const route = `/buscar-contratos/consultar-cliente?nome=${nome}`;

  return await api
    .get(route)
    .then((res) => {
      if (res.status === 200) {
        return { success: true, data: res.data };
      }
    })
    .catch((err) => ({ success: false }));
};

export const updateClientes = async (data) => {
  const route = `/auth/cliente-update`;

  return await api
    .put(route, data)
    .then((res) => {
      if (res.status === 200) {
        return { success: true };
      }
    })
    .catch((err) => ({ success: false, msg: err.response.data.msg }));
};

export const pesquisarFornecedor = async (cnpj) => {
  cnpj = formatted.numero(cnpj);
  const route = `/buscar-contratos/consultar-cnpj?cnpj=${cnpj}`;

  return await api
    .get(route)
    .then((res) => {
      if (res.status === 200) {
        return {
          success: true,
          data: res.data,
        };
      } else {
        return {
          success: false,
        };
      }
    })
    .catch(() => ({ success: false }));
};

export const atualizarContatoFornecedor = async (data) => {
  const route = `/fornecedor/atualizar-contatos`;

  return await api
    .put(route, data)
    .then((res) => {
      if (res.status === 200) {
        return { success: true };
      }
    })
    .catch((err) => ({ success: false }));
};

export const getPreferenciasColunas = async () => {
  const route = `/relatorio/preferencia-relatorio`;

  return await api
    .get(route)
    .then((res) => {
      if (res.status === 200) {
        return {
          success: true,
          data: res.data.data,
        };
      }
    })
    .catch((error) => ({ success: false, message: error.response.data }));
};

export const putPreferenciasColunas = async (body) => {
  const route = `/relatorio/preferencia-relatorio-edit`;

  return await api
    .put(route, body)
    .then((res) => {
      if (res.status === 200) {
        return {
          success: true,
        };
      }
    })
    .catch((error) => ({ success: false, message: error.response.data }));
};

export const intergracaoPNCP = async (id) => {
  const route = `/integracao/pncp?id=${id}`;

  return await api
    .post(route)
    .then((res) => {
      if (res.status === 200) {
        return {
          success: true,
          data: res.data,
        };
      }
    })
    .catch((error) => ({ success: false, message: error.response.data }));
};

export const putCotacaoInterna = async (body) => {
  const route = `/cotacao-interna/incluir-contrato`;

  return await api
    .put(route, body)
    .then((res) => {
      if (res.status === 200) {
        return {
          success: true,
          data: res.data.contrato_id,
        };
      }
    })
    .catch((error) => ({ success: false, message: error.response.data }));
};

export const putIncluirArquivoCotacao = async (contrato_id, file) => {
  const route = `/cotacao-interna/atualizar-link-contrato/${contrato_id}`;

  const formdata = new FormData();

  formdata.append("file", file);

  return await api
    .put(route, formdata)
    .then((res) => {
      if (res.status === 200) {
        return {
          success: true,
          data: res.data,
        };
      }
    })
    .catch((error) => ({ success: false, message: error.response.data }));
};
