import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  IconButton,
  Toolbar,
  Typography,
  Checkbox,
  Tooltip,
  Fab,
  Badge,
  Autocomplete,
  TextField,
  Grid as GridMaterial,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import InfoIcon from "@mui/icons-material/Info";
import PrintList from "../PrintList";
import UploadFileIcon from "@mui/icons-material/UploadFile";

import "./style.css";
import { maskCurrency } from "../../Utils";
import ModalInfo from "../ModalInfo";
import { relatorioExcel, relatorioPdf } from "../../Services";
import AddButton from "./AddButton";
import FormularioCotacao from "../ModalFormularioCotacao";
import AutocompleteEditable from "../AutoCompleteEditable";
import { Grid } from "react-virtualized";

const translate = {
  columnHeaderSortIconLabel: "Ordenar",
  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} itens selecionados`
      : `${count.toLocaleString()} item selecionado`,
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
  rowsPerPage: "Linhas por página",
  noRowsLabel: "Nenhum resultado encontrado!",
  noResultsOverlayLabel: "Utilize o menu ao lado para iniciar sua pesquisa!",
};

const PrintBtn = ({ content = 6, onClick }) => {
  return (
    <Badge badgeContent={content} color="error">
      <Fab onClick={onClick}>
        <PrintIcon />
      </Fab>
    </Badge>
  );
};

const InsertCotacao = ({ content = 6, onClick }) => {
  return (
    <Fab onClick={onClick}>
      <UploadFileIcon />
    </Fab>
  );
};

const notFound = "-----";

const DataTable = ({
  data = [],
  setData,
  loading,
  addNotification,
  openPesquisar,
  page,
  handlePageSize,
  onPageChange,
  filters,
  clearHeader,
  setClearHeader,
}) => {
  const [header, setHeader] = React.useState({
    media: 0,
    mediana: 0,
    maiorValor: 0,
    menorValor: 0,
  });

  const [allSelected, setAllSelected] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const [remover, setRemover] = React.useState([]);
  const [open, setOpen] = React.useState({
    printList: false,
    info: false,
    addCotacao: false,
  });
  const [dataModal, setDataModal] = React.useState({});
  const [loadingBtn, setLoadingBtn] = React.useState({
    pdf: false,
    xlxs: false,
  });
  const [lista, setLista] = React.useState([]);
  const [idSelecionado, setIdSelecionado] = React.useState(null);
  const [tituloPesquisa, setTituloPesquisa] = React.useState(null);

  const handleOpenPrint = () => setOpen({ ...open, printList: true });
  const handleClosePrint = () => setOpen({ ...open, printList: false });
  const handleCloseInfo = () => setOpen({ ...open, info: false });
  const handleCloseCotacao = () => setOpen({ ...open, addCotacao: false });

  const onRemove = (item, removerTodos = false, _index) => {
    let _selected = Object.assign([], selected);

    if (Array.isArray(item)) {
      for (let index = 0; index < item.length; index++) {
        const _id = item[index];

        _selected = _selected.filter((i) => i._id !== _id);
      }
    } else {
      _selected = _selected.filter((i) => i._id !== item);
    }

    if (!_selected.length) {
      setAllSelected(false);
    }

    setSelected(_selected);
    addNotification("Item removido com sucesso", true, "success");
  };

  const onSelect = (e, v) => {
    let _selected = Object.assign([], selected);
    let _remover = Object.assign([], remover);
    if (e.target.checked) {
      _selected = [v, ..._selected];
      _remover = _remover.filter((i) => i.id !== v.id);
    } else {
      _selected = _selected.filter((item) => item._id !== v._id);
      _remover = [v, ..._remover];
    }
    if (_selected.length === data.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }

    setRemover(_remover);
    setSelected(_selected);
  };

  const selectAll = (e) => {
    let _selected = [];
    let isSelected = false;

    if (e.target.checked) {
      _selected = [...new Set([...selected, ...data])];
      isSelected = e.target.checked;
    } else {
      _selected = selected.filter(
        (i) => data.findIndex((j) => j._id === i._id) === -1
      );
    }

    setAllSelected(isSelected);
    setSelected(_selected);
    setRemover([]);
  };

  const columns = [
    {
      field: "any",
      headerName: <Checkbox checked={allSelected} onChange={selectAll} />,
      flex: 0.05,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Checkbox
            id={params.id}
            checked={selected.filter((i) => i._id === params.id).length > 0}
            onChange={(e) => onSelect(e, params.row)}
          />
        );
      },
    },
    {
      field: "descricao_detalhada_item",
      headerName: "Material/Serviço",
      flex: 0.2,
      disableColumnMenu: true,
      disableColumnSelector: true,
      renderCell: (params) => {
        return (
          <span
            title={
              params.row?.descricao_detalhada_item
                ? params.row.descricao_detalhada_item
                : notFound
            }
            className="MuiDataGrid-cellContent"
          >
            <Typography className="MuiDataGrid-cellContent">
              {params.row?.descricao_item
                ? params.row.descricao_item
                : params.row.descricao_detalhada_item}
            </Typography>
          </span>
        );
      },
    },
    {
      headerName: "Comprador",
      field: "comprador",
      flex: 0.15,
      disableColumnMenu: true,
      disableColumnSelector: true,
      renderCell: (params) => (
        <span
          title={
            params.row?.uasg.uasg_nome ? params.row.uasg.uasg_nome : notFound
          }
          className="MuiDataGrid-cellContent"
        >
          <Typography className="MuiDataGrid-cellContent">
            {params.row?.uasg.uasg_nome ? params.row.uasg.uasg_nome : notFound}
          </Typography>
        </span>
      ),
    },
    {
      headerName: "Fornecedor",
      field: "fornecedor",
      description: "Fornecedor",
      flex: 0.15,
      disableColumnMenu: true,
      renderCell: (params) => (
        <span
          title={params.row.fornecedor.fornecedor_razao_social}
          className="MuiDataGrid-cellContent"
        >
          <Typography className="MuiDataGrid-cellContent">
            {params.row?.fornecedor.fornecedor_razao_social
              ? params.row.fornecedor.fornecedor_razao_social
              : notFound}
          </Typography>
        </span>
      ),
    },
    {
      field: "unidade_fornecimento",
      headerName: "Unidade",
      type: "number",
      align: "center",
      headerAlign: "center",
      flex: 0.05,
      disableColumnMenu: true,
    },
    {
      field: "quantidade_item",
      headerName: "Qnt",
      type: "number",
      align: "center",
      headerAlign: "center",
      flex: 0.05,
      disableColumnMenu: true,
    },
    {
      field: "valorUnitario",
      headerName: "Valor unitário",
      type: "number",
      align: "center",
      headerAlign: "center",
      flex: 0.1,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div>
          <span>
            {params.row?.valorUnitario
              ? maskCurrency(`${params.row.valorUnitario}`)
              : notFound}
          </span>
        </div>
      ),
    },
    {
      field: "acoes",
      headerName: "Ver mais",
      flex: 0.05,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const handleClick = () => {
          setDataModal(params.row);
          setOpen({ ...open, info: true });
        };

        return (
          <IconButton
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClick}
          >
            <InfoIcon fontSize="medium" />
          </IconButton>
        );
      },
    },
  ];

  const calcMedian = (array) => {
    const { length } = array;

    if (length < 1) return 0;

    // Ordena o array
    array.sort((a, b) => a - b);

    if (length % 2) {
      // Tamanho do array é ímpar
      return array[(length + 1) / 2 - 1];
    } else {
      // Tamanho do array é par
      return 0.5 * [array[length / 2 - 1] + array[length / 2]];
    }
  };

  const handleHeadervalues = () => {
    let _valores = Object.assign([], selected);
    let somenteValores = _valores.map((item) => item.valorUnitario);

    // Média
    let media =
      somenteValores.reduce((acc, current) => acc + current, 0) /
      somenteValores.length;

    // Mediana
    let mediana = calcMedian(somenteValores);

    // Maior valor
    let maiorValor =
      somenteValores.length > 0 ? Math.max(...somenteValores) : 0;

    // Menor valor
    let menorValor =
      somenteValores.length > 0 ? Math.min(...somenteValores) : 0;

    setHeader({
      media: media || 0,
      mediana: mediana || 0,
      maiorValor: maiorValor || 0,
      menorValor: menorValor || 0,
    });
  };

  const ToolbarTable = () => (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: 26,
        justifyContent: "space-around",
        backgroundColor: "#cfd8dc",
        border: "1px solid #cfd8dc",
      }}
    >
      <Typography
        sx={{
          mr: 2,
          ml: 1,
          display: { xs: "none", md: "flex" },
          fontWeight: 600,
          letterSpacing: ".05rem",
          color: "inherit",
          textDecoration: "none",
          textAlign: "center",
        }}
      >
        Média:
        {maskCurrency(header.media)}
      </Typography>
      <Typography
        sx={{
          mr: 2,
          ml: 1,
          display: { xs: "none", md: "flex" },
          fontWeight: 600,
          letterSpacing: ".05rem",
          color: "inherit",
          textDecoration: "none",
          textAlign: "center",
        }}
      >
        Mediana:
        {maskCurrency(header.mediana)}
      </Typography>
      <Typography
        sx={{
          mr: 2,
          ml: 1,
          display: { xs: "none", md: "flex" },
          fontWeight: 600,
          letterSpacing: ".05rem",
          color: "inherit",
          textDecoration: "none",
          textAlign: "center",
        }}
      >
        Menor Valor:
        {maskCurrency(header.menorValor)}
      </Typography>
      <Typography
        sx={{
          mr: 2,
          ml: 1,
          display: { xs: "none", md: "flex" },
          fontWeight: 600,
          letterSpacing: ".05rem",
          color: "inherit",
          textDecoration: "none",
          textAlign: "center",
        }}
      >
        Maior Valor:
        {maskCurrency(header.maiorValor)}
      </Typography>
    </div>
  );

  const getPDF = async (data, responsavel, colunas, titulo) => {
    if (loadingBtn.pdf) return;

    if (!responsavel) {
      addNotification(
        "Indique o responsável para exportar o relatório",
        true,
        "warning"
      );
      return;
    }

    let _body = {};
    let totalValor = 0;
    let totalQuantidade = 0;

    for (let i = 0; i < data.length; i++) {
      const { nome, itens, quantidade } = data[i];
      const id = crypto.randomUUID();
      const nomeEId = `${nome}${id}`;

      _body[nomeEId] = {
        arr: [],
        vlr: 0,
        somenteVlr: [],
        size: itens.length + 1,
      };

      for (let j = 0; j < itens.length; j++) {
        const item = itens[j];
        _body[nomeEId].arr.push({
          _id: item._id,
          quantidade: item.quantidade_item,
          quantidadeCotacao: quantidade,
        });

        _body[nomeEId].somenteVlr.push(item.valorUnitario);

        _body[nomeEId].vlr += item.valorUnitario;

        totalValor += item.valorUnitario;
        totalQuantidade += item.quantidade_item
          ? Number(item.quantidade_item)
          : 0;
      }
    }

    let body = {
      titulo,
      itens: _body,
      responsavel,
      dataInicial: filters.dataInicial,
      dataFinal: filters.dataFinal,
      totalQuantidade,
      totalValor,
      inserirmedia: true,
      inserirmediana: true,
      inserirmenorValor: true,
      inserirmaiorValor: true,
      inserirvalorTotal: true,
      inserirfonte: true,
      inserirorgao: true,
      inserirfornecedor: true,
      inseriritem: true,
      inserirunidade: true,
      inserirquantidade: true,
      inserirvalorUnitario: true,
    };

    if (Array.isArray(colunas)) {
      colunas.forEach((e, index) => {
        if (index > 0) {
          body[e.name] = e.checked;
        }
      });
    }

    setLoadingBtn({ ...loadingBtn, pdf: true });
    await relatorioPdf(body);
    setLoadingBtn({ ...loadingBtn, pdf: false });
  };

  const getExcel = async (data, responsavel) => {
    if (loadingBtn.xlxs) return;

    if (!responsavel) {
      addNotification(
        "Indique o responsável para exportar o relatório",
        true,
        "warning"
      );
      return;
    }

    let _body = [];

    for (let i = 0; i < data.length; i++) {
      const { itens } = data[i];

      for (let j = 0; j < itens.length; j++) {
        const item = itens[j];
        _body.push(item._id);
      }
    }

    let body = {
      itens: _body,
      responsavel,
      dataInicial: filters.dataInicial,
      dataFinal: filters.dataFinal,
    };
    setLoadingBtn({ ...loadingBtn, xlxs: true });
    await relatorioExcel(body);
    setLoadingBtn({ ...loadingBtn, xlxs: false });
  };

  React.useEffect(() => {
    handleHeadervalues();
  }, [selected.length]);

  React.useEffect(() => {
    let _selected = selected.filter(
      (i) => data.findIndex((j) => j._id === i._id) > -1
    );

    if (_selected.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [page.size, page.number, data]);

  React.useEffect(() => {
    if (clearHeader) {
      setHeader({ media: 0, mediana: 0, maiorValor: 0, menorValor: 0 });
      setAllSelected(false);
      setClearHeader(false);
      setSelected([]);
    }
  }, [clearHeader]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        marginLeft: openPesquisar ? 310 : "inherit",
      }}
    >
      <AutocompleteEditable
        label={"Item"}
        label2={"Quantidade"}
        lista={lista}
        setLista={setLista}
        setIdSelecionado={setIdSelecionado}
        setTituloPesquisa={setTituloPesquisa}
      />
      <DataGrid
        componentsProps={{
          pagination: {
            labelRowsPerPage: "Linhas por página",
            labelDisplayedRows: ({ from, to, count }) => {
              return `${from}–${to} de ${
                count !== -1 ? count : `more than ${to}`
              }`;
            },
          },
        }}
        density="compact"
        loading={loading}
        rows={data || []}
        columns={columns}
        pagination
        page={page.number}
        pageSize={page.size}
        onPageSizeChange={handlePageSize}
        onPageChange={onPageChange}
        rowCount={page.count}
        rowsPerPageOptions={[100, 50, 25]}
        localeText={translate}
        disableSelectionOnClick
        getRowId={(item) => item._id}
        paginationMode="server"
        components={{
          Header: ToolbarTable,
        }}
      />

      <div className="styled-float-bnt">
        <AddButton
          selecionados={selected}
          remover={remover}
          addNotification={addNotification}
          lista={lista}
          setLista={setLista}
          setSelecionados={setSelected}
          clear={setClearHeader}
          idSelecionado={idSelecionado}
          tituloPesquisa={tituloPesquisa}
        />
        <PrintBtn onClick={handleOpenPrint} content={lista.length} />
        <InsertCotacao onClick={() => setOpen({ ...open, addCotacao: true })} />
      </div>
      <PrintList
        open={open.printList}
        onClose={handleClosePrint}
        onRemove={onRemove}
        data={lista}
        setData={setLista}
        addNotification={addNotification}
        getPDF={getPDF}
        getExcel={getExcel}
        loading={loadingBtn}
      />
      <ModalInfo
        open={open.info}
        onClose={handleCloseInfo}
        addNotification={addNotification}
        data={dataModal}
        setData={setData}
      />
      <FormularioCotacao
        open={open.addCotacao}
        onClose={handleCloseCotacao}
        addNotification={addNotification}
      />
    </div>
  );
};

export default React.memo(DataTable);
