import React, { memo, useState, Fragment, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListSubheader,
  Tab,
  TextField,
  Typography,
  Checkbox,
  Tooltip,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import GridOnIcon from "@mui/icons-material/GridOn";
import DefaultModal from "../DefaultModal";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { DropableBox } from "../DropableBox";
import {
  ArrowBackIos,
  ArrowDownward,
  ArrowDropDown,
  ArrowDropUp,
  ArrowUpward,
  Delete,
  Print,
  ReplayOutlined,
  Warning,
} from "@mui/icons-material";
import "./style.css";
import DefaultDropDown, {
  DefaultDropDownWithCheckBox,
} from "../DefaultDropdown";
import {
  buscarLogs,
  salvarLogs,
  deletarLog,
  getPreferenciasColunas,
  putPreferenciasColunas,
  buscarLogsPorId,
} from "../../Services";
import moment from "moment";
import { AutoSizer, List as ListVirtualized } from "react-virtualized";
import { maskCurrency } from "../../Utils";

const relatorioColunas = [
  { name: "todos", checked: true, title: "Todas" },
  { name: "inserirdata", checked: true, title: "Data publicação" },
  { name: "inserirmedia", checked: true, title: "Média" },
  { name: "inserirmediana", checked: true, title: "Mediana" },
  { name: "inserirmenorValor", checked: true, title: "Menor valor" },
  { name: "inserirmaiorValor", checked: true, title: "Maior valor" },
  { name: "inserirvalorTotal", checked: true, title: "Valor total" },
  { name: "inserirfonte", checked: true, title: "Fonte" },
  { name: "inserirorgao", checked: true, title: "Orgão comprador" },
  { name: "inserirfornecedor", checked: true, title: "Fornecedor" },
  { name: "inseriritemdescricao", checked: true, title: "Descrição" },
  {
    name: "inseriritemdescricaodetalhada",
    checked: true,
    title: "Descrição detalhada",
  },
  { name: "inserirunidade", checked: true, title: "Unidade de medida" },
  { name: "inserirquantidade", checked: true, title: "Quantidade" },
  { name: "inserirvalorUnitario", checked: true, title: "Valor unitário" },
];

const options = [
  { title: "Imprimir pesquisa", id: 0 },
  { title: "Salvar nova pesquisa", id: 1 },
  { title: "Editar pesquisa", id: 2 },
  { title: "Iniciar nova pesquisa", id: 3 },
];

const Container = memo(function Container({ data, setData, onRemove }) {
  const [todosSelecionados, setTodosSelecionados] = useState(false);
  const [selecionados, setSelecionados] = useState([]);
  const [ordenar, setOrdenar] = useState("Nenhum");

  const onChangeQuantidade = (e, _index, index) => {
    const _data = JSON.parse(JSON.stringify(data));

    _data[index].itens[_index].quantidade_item = e.target.value;

    setData(_data);
  };

  const handleRemoveAll = async (_index) => {
    let _data = Object.assign([], data);
    let onlyIds = _data[_index].itens.map((i) => i._id);

    onRemove(onlyIds, onlyIds.length === _data[_index].itens.length, _index);

    _data.splice(_index, 1);
    setData(_data);
  };

  const onRemoveItem = (i = [], _index) => {
    let _data = Object.assign([], data);
    let onlyIds = i.map((i) => i._id);

    _data[_index].itens = _data[_index].itens.filter(
      (j) => !i.some((k) => k._id === j._id)
    );

    onRemove(onlyIds, onlyIds.length === _data[_index].itens.length, _index);
    setData(_data);
  };

  const moveBox = (index, up = false) => {
    const _data = Object.assign([], data);
    const fromIndex = index;
    const toIndex = !up ? index + 1 : index - 1;
    const element = _data.splice(fromIndex, 1)[0];
    _data.splice(toIndex, 0, element);
    setData(_data);
  };

  const handleSort = (index, e) => {
    const _data = Object.assign([], data);
    if (e.id === 0) {
      _data[index].itens = _data[index].itens.sort(
        (a, b) => a.valorUnitario - b.valorUnitario
      );
    } else if (e.id === 1) {
      _data[index].itens = _data[index].itens.sort(
        (a, b) => b.valorUnitario - a.valorUnitario
      );
    }

    setData(_data);
  };

  const handleMove = (index, arr = [], id) => {
    const _data = Object.assign([], data);
    let newIndex = _data.findIndex((z) => z.id === id);
    let remover = [];

    for (let j = 0; j < arr.length; j++) {
      const e = arr[j];

      let a = _data[index].itens[e.index];
      remover.push(a);

      if (_data[newIndex].itens.findIndex((i) => i._id === a._id) === -1) {
        _data[newIndex].itens.push(a);
      }
    }

    _data[index].itens = _data[index].itens.filter(
      (i) => !remover.some((j) => j._id === i._id)
    );

    if (!_data[index].itens.length) {
      _data.splice(index, 1);
    }

    setData(_data);
  };

  const renderRow = ({ index, key, style }) => {
    const handleAll = (e) => {
      const { checked } = e.target;
      let _selecionados = [];
      const { itens } = data[index];

      if (checked) {
        _selecionados = itens.map((i, index) => ({
          _id: i._id,
          title: i.title,
          index: index,
        }));
        setSelecionados();
      }

      setSelecionados(_selecionados);
      setTodosSelecionados(
        _selecionados.length ? _selecionados.length === itens.length : false
      );
    };

    const handleSelect = (item, e, index) => {
      const { checked } = e.target;
      if (checked) {
        setSelecionados([...selecionados, { _id: item._id, index: index }]);
      } else {
        setSelecionados(selecionados.filter((i) => i._id !== item._id));
      }
    };

    const handleRemoveItem = () => {
      if (!selecionados.length) {
        return;
      }
      onRemoveItem(selecionados, index);
    };

    return (
      <div
        key={key}
        style={{
          ...style,
          width: "98%",
          overflowY: "hidden",
        }}
        className="drop-container"
      >
        <div style={{ height: "40%" }}>
          <div style={{ display: "flex" }}>
            <Typography
              variant="overline"
              fontSize={14}
              style={{
                backgroundColor: "rgba(218, 165, 32, 0.73)",
                padding: "5px",
                borderRadius: "3px",
                textAlign: "justify",
                width: "100%",
                display: "block",
                margin: "6px",
                maxHeight: 70,
                overflow: "hiddens",
              }}
              color="InfoText"
            >
              {data[index].nome}
            </Typography>
            <div style={{ width: "8%", display: "flex" }}>
              {index !== data.length - 1 && (
                <Tooltip title="Mover quadro para baixo">
                  <IconButton size="small" onClick={() => moveBox(index)}>
                    <ArrowDownward />
                  </IconButton>
                </Tooltip>
              )}
              {index !== 0 && (
                <Tooltip title="Mover quadro para cima">
                  <IconButton onClick={() => moveBox(index, true)}>
                    <ArrowUpward />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>

          <Button
            color="error"
            className="remove-btn"
            onClick={() => handleRemoveAll(index)}
          >
            Excluir lista
          </Button>

          {data[index].itens.length ? (
            <div
              style={{
                paddingLeft: 16,
                paddingRight: 16,
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 20,
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox checked={todosSelecionados} onChange={handleAll} />{" "}
                <span style={{ marginRight: 30, fontSize: 16 }}>
                  <b>SELECIONAR TODOS:</b>
                </span>
                <DefaultDropDown
                  title="Mover para"
                  data={data.filter((i) => i.nome !== data[index].nome)}
                  onSelect={(e) => {
                    handleMove(index, selecionados, e.id);
                    setSelecionados([]);
                  }}
                />
                <Button
                  onClick={handleRemoveItem}
                  variant="outlined"
                  color="error"
                  style={{ marginLeft: 10 }}
                >
                  Excluir
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 15,
                }}
              >
                <span>
                  <b>Ordenar por:</b>
                </span>
                <DefaultDropDown
                  variant="outlined"
                  title={ordenar || "Nenhum"}
                  onSelect={(e) => {
                    handleSort(index, e);
                    setOrdenar(e.title);
                  }}
                  data={[
                    { title: "Nenhum", id: -1 },
                    { title: "Menor valor", id: 0 },
                    { title: "Maior valor", id: 1 },
                  ]}
                />
              </div>
            </div>
          ) : null}
        </div>

        <List style={{ height: "60%", overflow: "scroll" }}>
          {data[index].itens.length > 0 &&
            data[index].itens.map((i, _index) => (
              <ListItem
                key={_index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: 14,
                }}
              >
                <div style={{ width: "90%" }}>
                  <Checkbox
                    onChange={(e) => handleSelect(i, e, _index)}
                    checked={selecionados.some((j) => j._id === i._id)}
                  />
                  <b style={{ marginRight: 8 }}>{_index + 1}.</b>
                  {i.fornecedor.fornecedor_razao_social} / {i.descricao_item} -{" "}
                  {maskCurrency(i.valorUnitario)}
                </div>
                <div style={{ width: "10%" }}>
                  <TextField
                    label="Quantidade"
                    type="number"
                    value={i.quantidade_item}
                    onChange={(e) => onChangeQuantidade(e, _index, index)}
                    size="small"
                  />
                </div>
              </ListItem>
            ))}
        </List>
      </div>
    );
  };

  return (
    <div
      style={{ clear: "both", marginRight: 30, width: "100%", height: "100%" }}
    >
      <AutoSizer>
        {({ width, height, style }) => (
          <ListVirtualized
            style={style}
            width={width}
            height={height}
            rowHeight={350}
            rowRenderer={renderRow}
            rowCount={data.length}
            overscanRowCount={1}
          />
        )}
      </AutoSizer>
    </div>
  );
});

const PrintList = ({
  data = [],
  open,
  onClose,
  onRemove,
  getPDF,
  getExcel,
  setData,
  loading,
  addNotification,
}) => {
  const [nome, setNome] = useState("");
  const [tab, setTab] = useState("1");
  const [acao, setAcao] = useState(null);
  const [tituloPesquisa, setTituloPesquisa] = useState("");
  const [pesquisasSalvas, setPesquisasSalvas] = useState([]);
  const [collapse, setCollapse] = useState(null);
  const [load, setLoad] = useState({
    print: false,
    save: false,
    delete: false,
    changetab: false,
    detalhesPesquisa: false,
    index: null,
  });
  const [logId, setLogId] = useState(null);
  const [nomePesquisaRetomada, setNomePesquisaRetomada] = useState("");
  const [colunasImpressao, setColunasImpressao] = useState(relatorioColunas);

  const handleTabChange = async (e, v) => {
    setLoad({ ...load, changetab: true });
    if (v === "2") {
      const res = await buscarLogs();
      if (res.success) {
        setPesquisasSalvas(res.data);
      }
    }
    setTab(v);
    setLoad({ ...load, changetab: false });
  };

  const handleChange = (e) => {
    if (acao === 2) {
      setNomePesquisaRetomada(e.target.value);
    } else {
      setTituloPesquisa(e.target.value);
    }
  };

  const handleSave = async () => {
    const titulo = acao === 1 ? tituloPesquisa : nomePesquisaRetomada;

    if (!titulo) {
      addNotification("Adicione um título para sua pesquisa!", true, "info");
      return;
    }

    let _data = Object.assign([], data);
    _data = _data.map((i) => ({
      nome: i.nome,
      itens: i.itens.map((j) => ({
        _id: j._id,
        quantidade: j.quantidade_item,
      })),
    }));

    const body = { titulo: titulo, itens: _data, date: moment() };

    setLoad({ ...load, save: true });
    const _id = logId && acao === 2 ? logId : null;
    const res = await salvarLogs(body, _id);
    setLoad({ ...load, save: false });

    if (res.success) {
      setAcao(null);
      setTituloPesquisa("");
      addNotification("Pesquisa salva com sucesso", true, "success");
    } else {
      addNotification(res.msg, true, "error");
    }
  };

  const handleAcao = (id) => {
    if (id < 3) {
      setAcao(id);
    } else {
      setLogId(null);
      setData([]);
    }
  };

  const imprimirPesquisaSalva = async (index) => {
    if (load.index) {
      return;
    }
    const itens = pesquisasSalvas[index]?.pesquisas;

    if (itens) {
      setLoad({ ...load, print: true, index: index });
      await getPDF(itens, "*****", null, pesquisasSalvas[index]?.titulo);
      setLoad({ ...load, print: false, index: null });
    }
  };

  const handleDeleteLog = async (id, index) => {
    setLoad({ ...load, delete: true, index: index });
    const res = await deletarLog(id);
    setLoad({ ...load, delete: false, index: null });

    if (res.success) {
      let itens = pesquisasSalvas.filter((i, _index) => index !== _index);
      setPesquisasSalvas(itens);
    }
  };

  const handleRetomar = (index, i) => {
    setData(
      pesquisasSalvas[index].pesquisas.map((i, index) => ({
        ...i,
        id: Date.now() + index,
      }))
    );
    setLogId(i.id);
    setNomePesquisaRetomada(i.titulo);
    addNotification("Pesquisa retomada com sucesso!", true, "success");
    setTab("1");
  };

  const buscarPreferencias = async () => {
    if (open) {
      const res = await getPreferenciasColunas();

      if (res.success) {
        const item = relatorioColunas.map((p) => ({
          ...p,
          checked: res.data[p.name],
        }));

        setColunasImpressao(item);
      }
    }
  };

  const handleCheck = async (e) => {
    let _itens = [...colunasImpressao];
    if (_itens[e.index].name === "todos") {
      if (!e.checked) {
        _itens = _itens.map((i) => ({
          ...i,
          checked: true,
        }));
      } else {
        _itens = _itens.map((i) => ({
          ...i,
          checked: false,
        }));
      }
    } else {
      _itens[e.index] = {
        ..._itens[e.index],
        checked: !_itens[e.index].checked,
      };
      if (_itens.every((i) => i.checked)) {
        _itens[0] = { ..._itens[0], checked: true };
      } else {
        _itens[0] = { ..._itens[0], checked: false };
      }
    }
    const body = {};

    _itens.map((p) => {
      body[p.name] = p.checked;
    });

    setColunasImpressao(_itens);

    await putPreferenciasColunas(body);
  };

  const buscarDetalhesPesquisa = async (id, index) => {
    let mostrar = index === collapse ? null : index;

    if (mostrar === index) {
      setLoad({ ...load, index, detalhesPesquisa: true });
      const res = await buscarLogsPorId(id);

      if (res.success) {
        const _data = [...pesquisasSalvas];

        _data[index] = res.data.data;
        setPesquisasSalvas(_data);

        setCollapse(mostrar);
      }

      setLoad({ ...load, index: null, detalhesPesquisa: false });
    } else {
      setLoad({ ...load, index: null, detalhesPesquisa: false });
      setCollapse(mostrar);
    }
  };

  useEffect(() => {
    buscarPreferencias();
  }, [open]);

  return (
    <DefaultModal
      open={open}
      title="ITENS SELECIONADOS PARA IMPRESSÃO"
      onClose={onClose}
      style={{ width: "90vw", height: "85vh" }}
      children={
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange}>
              <Tab label="itens selecionados" value="1" />
              <Tab
                label={
                  <span>
                    {load.changetab ? (
                      <CircularProgress size={14} style={{ marginRight: 10 }} />
                    ) : null}
                    Pesquisas salvas
                  </span>
                }
                value="2"
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            {data.length > 0 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="styled-list">
                  <Container
                    data={data}
                    setData={setData}
                    onRemove={onRemove}
                  />
                </div>
                <Box
                  style={{
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    bottom: 30,
                    gap: 10,
                  }}
                >
                  {acao === null ? (
                    <DefaultDropDown
                      title="Ação"
                      data={logId ? options : options.filter((i) => i.id !== 2)}
                      onSelect={(e) => {
                        handleAcao(e.id);
                      }}
                    />
                  ) : (
                    <IconButton onClick={() => setAcao(null)}>
                      <ArrowBackIos />
                    </IconButton>
                  )}

                  {acao === 0 ? (
                    <>
                      <TextField
                        label="Responsável"
                        size="small"
                        required
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                        style={{ marginRight: 15 }}
                        inputProps={{ maxLength: 50 }}
                      />
                      <TextField
                        value={nomePesquisaRetomada}
                        size="small"
                        label="Título da pesquisa"
                        onChange={(e) =>
                          setNomePesquisaRetomada(e.target.value)
                        }
                        style={{ marginRight: 15 }}
                        inputProps={{ maxLength: 70 }}
                      />
                      <DefaultDropDownWithCheckBox
                        title={"Colunas"}
                        data={colunasImpressao}
                        onSelect={handleCheck}
                      />
                      <LoadingButton
                        size="small"
                        loading={loading.pdf}
                        variant="contained"
                        style={{ backgroundColor: "#ff0000", margin: 2 }}
                        onClick={() =>
                          getPDF(
                            data,
                            nome,
                            colunasImpressao,
                            nomePesquisaRetomada
                          )
                        }
                      >
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            margin: 2,
                          }}
                        >
                          <PictureAsPdfIcon />
                          <span style={{ marginLeft: 3 }}>Exportar</span>
                        </span>
                      </LoadingButton>
                      <LoadingButton
                        loading={loading.xlxs}
                        size="small"
                        variant="contained"
                        style={{ backgroundColor: "#008000", margin: 2 }}
                        onClick={() => getExcel(data, nome)}
                      >
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            margin: 2,
                          }}
                        >
                          <GridOnIcon />
                          <span style={{ marginLeft: 3 }}>Exportar</span>
                        </span>
                      </LoadingButton>
                    </>
                  ) : null}

                  {acao === 1 || acao == 2 ? (
                    <>
                      <TextField
                        value={
                          acao == 2 ? nomePesquisaRetomada : tituloPesquisa
                        }
                        size="small"
                        label="Título da pesquisa"
                        onChange={handleChange}
                        inputProps={{ maxLength: 70 }}
                      />
                      <LoadingButton
                        onClick={handleSave}
                        size="small"
                        variant="contained"
                      >
                        {load.save ? <CircularProgress size={15} /> : "Salvar"}
                      </LoadingButton>
                    </>
                  ) : null}
                </Box>
              </div>
            ) : (
              <div className="img-container">
                <div className="no-data" />
                <Typography style={{ width: "70%" }}>
                  <Warning
                    style={{
                      color: "#FFCC00",
                      verticalAlign: "sub",
                      marginRight: 5,
                      fontSize: 25,
                    }}
                  />
                  Ops! Não há nenhum item selecionado, selecione algum item para
                  visualizar as opções de impressão.
                </Typography>
              </div>
            )}
          </TabPanel>
          <TabPanel value="2">
            <List sx={{ overflow: "auto", height: "70vh" }}>
              {pesquisasSalvas.map((i, index) => (
                <div key={index}>
                  <ListItem sx={{ justifyContent: "space-between" }}>
                    <Typography variant="body1">
                      {index + 1}. PESQUISA: {i.titulo} -{" "}
                      {moment(i.date).format("DD/MM/YYYY HH:mm:ss")}
                    </Typography>

                    <div>
                      {i.pesquisas.length ? (
                        <>
                          <IconButton
                            title="Imprimir"
                            onClick={() => imprimirPesquisaSalva(index)}
                          >
                            {load.print && index === load.index ? (
                              <CircularProgress size={20} />
                            ) : (
                              <Print />
                            )}
                          </IconButton>
                          <IconButton
                            title="Retomar"
                            onClick={() => handleRetomar(index, i)}
                          >
                            <ReplayOutlined />
                          </IconButton>
                        </>
                      ) : null}
                      <IconButton
                        title="Excluir"
                        onClick={() => handleDeleteLog(i.id, index)}
                      >
                        {load.delete && load.index === index ? (
                          <CircularProgress size={20} />
                        ) : (
                          <Delete />
                        )}
                      </IconButton>
                      <IconButton
                        title={collapse === index ? "Fechar" : "Abrir"}
                        onClick={() => buscarDetalhesPesquisa(i.id, index)}
                      >
                        {load.detalhesPesquisa && load.index === index ? (
                          <CircularProgress size={20} />
                        ) : collapse === index ? (
                          <ArrowDropUp />
                        ) : (
                          <ArrowDropDown />
                        )}
                      </IconButton>
                    </div>
                  </ListItem>
                  <Collapse in={collapse === index} sx={{ pl: 3 }}>
                    <List>
                      <Divider />
                      {i.pesquisas
                        ? i.pesquisas.map((j, jIndex) => (
                            <Fragment key={jIndex}>
                              <ListSubheader>
                                {index + 1}.{jIndex + 1} - {j.nome}
                              </ListSubheader>
                              {j.itens.map((k, kIndex) => (
                                <ListItem key={kIndex} sx={{ pl: 4 }} divider>
                                  {index + 1}.{jIndex + 1}.{kIndex + 1} -{" "}
                                  {k.descricao_detalhada_item}
                                </ListItem>
                              ))}
                            </Fragment>
                          ))
                        : null}
                    </List>
                  </Collapse>
                </div>
              ))}
            </List>
          </TabPanel>
        </TabContext>
      }
    />
  );
};

export default memo(PrintList);
